import { CategorySettings } from '@wix/search-results-settings-definitions';
import { SearchDocumentType } from '@wix/client-search-sdk';

type CategoryEntry = [SearchDocumentType, CategorySettings];

export function sortCategories(
  visibleCategories: [string, CategorySettings][],
) {
  const sortedCategories = visibleCategories
    // @ts-expect-error
    .sort((c1: CategoryEntry, c2: CategoryEntry) => c1[1].index - c2[1].index)
    .map(c => c[0]);
  return sortedCategories;
}
