import { ISearchResponse } from '@wix/client-search-sdk';

import { BiDocumentIds } from '../../../../SearchBox/platform/bi';
import { ISearchSample } from '../searchResultsControllerStore';

export function getDocumentIds({
  searchResponse,
  searchSamples,
  shouldShowSamples,
}: {
  searchResponse: ISearchResponse;
  searchSamples: ISearchSample[];
  shouldShowSamples: boolean;
}): BiDocumentIds {
  if (shouldShowSamples) {
    return searchSamples.reduce((acc, curr) => {
      return { ...acc, [curr.documentType]: curr.documents.map(d => d.id) };
    }, {});
  }
  return searchResponse.documents.reduce((acc, curr) => {
    if (!acc[curr.documentType]) {
      acc[curr.documentType] = [];
    }
    acc[curr.documentType].push(curr.id);
    return acc;
  }, {});
}
