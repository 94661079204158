import {
  IWidgetController,
  IControllerConfig,
  I$W,
} from '@wix/native-components-infra/dist/src/types/types';

import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';

import {
  ISearchResultsWixCode,
  IWidgetControllerConfig,
} from '../../../../lib/platform.types';
import { SearchResultsControllerStore } from './searchResultsControllerStore';
import { extractSettings } from './extractSettings';

interface ISettingsEvents {
  previewStateChange: {
    shouldHaveSearchResults: boolean;
    shouldSetNonAllDocumentType: boolean;
  };
}

export async function searchResultsControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const { config, reportError, wixCodeApi, getCategoryList } = controllerConfig;
  const categoryList = await getCategoryList();
  const { settings, publicData } = extractSettings(config, categoryList);
  const eventHandler = createEventHandler<ISettingsEvents>(publicData);

  let controllerStore: SearchResultsControllerStore;
  eventHandler.on('previewStateChange', value => {
    controllerStore.updateDemoMode(value);
  });

  eventHandler.onReset(() => {
    controllerStore.updateDemoMode({
      shouldHaveSearchResults: true,
      shouldSetNonAllDocumentType: false,
    });
  });

  try {
    controllerStore = new SearchResultsControllerStore(
      controllerConfig,
      settings,
    );
  } catch (error) {
    reportError &&
      reportError(error, {
        tags: {
          ssr: wixCodeApi.window.rendering.env === 'backend',
          demo: wixCodeApi.window.viewMode !== 'Site',
          mobile: wixCodeApi.window.formFactor === 'Mobile',
          fluid: 'unknown',
        },
      });
    throw error;
  }

  return {
    pageReady() {
      return controllerStore.setInitialState();
    },

    // @ts-expect-error
    updateConfig($w: I$W, controllerConfig: IControllerConfig) {
      const { settings, publicData } = extractSettings(
        controllerConfig,
        categoryList,
      );

      eventHandler.notify(publicData);
      void controllerStore.updateSettings(settings);
    },

    exports: () =>
      ({
        // NOTE: used for mocks in tests
        searchSDK: controllerStore.getSearchSDK(),
        changeQuery: controllerStore.changeQuery,
      } as ISearchResultsWixCode),
  };
}
