import Experiments from '@wix/fe-essentials-viewer-platform/experiments';
import { IPlatformAPI } from '@wix/native-components-infra/dist/src/types/types';

import { Spec, SPECS_SCOPE } from '../specs';

export const fetchExperiments = async (
  baseUrl: string,
  siteOwnerId?: string,
  visitorId?: string,
): Promise<Experiments> => {
  const experiments = new Experiments({
    baseUrl,
    requestContext:
      visitorId && siteOwnerId
        ? { forSiteVisitors: { visitorId, siteOwnerId } }
        : undefined,
    scope: SPECS_SCOPE,
  });
  await experiments.ready();
  return experiments;
};

export const experimentsNames = {
  productSortingEnabled: Spec.ProductsSorting,
  renderSeoTagsEnabled: Spec.RenderSeoTags,
  shorterSearchTermEnabled: Spec.ShorterSearchTerm,
};

export type ExperimentNames = typeof experimentsNames;

export type FeatureToggles = { [k in keyof ExperimentNames]: boolean };

export const getFeatureToggles = (experiments: Experiments): FeatureToggles => {
  return {
    productSortingEnabled: experiments.enabled(Spec.ProductsSorting),
    renderSeoTagsEnabled: experiments.enabled(Spec.RenderSeoTags),
    shorterSearchTermEnabled: experiments.enabled(Spec.ShorterSearchTerm),
  };
};

export const fallbackFeatureToggles: FeatureToggles = {
  productSortingEnabled: false,
  renderSeoTagsEnabled: false,
  shorterSearchTermEnabled: false,
};

export const loadAndParseFeatureToggles = async (
  baseUrl: string,
  platformAPIs: IPlatformAPI,
  errorLogger,
): Promise<FeatureToggles> => {
  try {
    return getFeatureToggles(
      await fetchExperiments(
        baseUrl,
        platformAPIs.bi?.ownerId,
        platformAPIs.bi?.visitorId,
      ),
    );
  } catch (ex) {
    errorLogger(ex);
    return fallbackFeatureToggles;
  }
};
