import { SearchDocumentType } from '@wix/client-search-sdk';

import {
  Settings,
  CategorySettings,
} from '@wix/search-results-settings-definitions';

type CategoryEntry = [SearchDocumentType, CategorySettings];

export const getVisibleCategories = (
  settings: Settings,
  availableDocumentTypes: SearchDocumentType[],
) => {
  const visibleCategories = Object.entries(settings.categoryList).filter(
    // @ts-expect-error
    (c: CategoryEntry) =>
      c[1].visible &&
      availableDocumentTypes.includes(c[0] as SearchDocumentType),
  );

  return visibleCategories;
};
