import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { searchResultsControllerFactory } from './Widget/platform/searchResultsControllerFactory';
import extendConfig from '../../lib/controllerPropsMapper';

const createController: CreateControllerFn = async props => {
  const extendedConfig = extendConfig(props);
  return searchResultsControllerFactory(extendedConfig);
};

export default createController;
